import Modal from 'bootstrap/js/dist/modal';
import $ from "./cashwrapper";
/**
 * @param {$} $ jQuery object
 * @param {SURPLEX} SURPLEX namespace
 */
;(function ($, SURPLEX) {
    SURPLEX.Bid = $.extend({}, {
        articleId: 0,
        usersLastBid: '',
        usersHighestBidFormatted: '',
        proposedBidFormatted: '',
        bidStep: 0,
        proposedBid: 0,
        userHasRequestedUpdate: false,
        userHasChangedBid: false,
        userHasSubmittedBid: false,
        lastBidResult: null,
        biddingModal: false,
        BID_STATUS: {
            PLEASE_COMPLETE_PROFILE: 12,
            SECURITY_DEPOSIT_MANDATORY: 13,
            COUNTRY_RESTRICTION: 25
        },
        BID_ERROR: {
            PLEASE_COMPLETE_PROFILE: 1,
            AUCTION_CLOSED: 2,
            PLEASE_ACCEPT_AVB: 3,
            INVALID_BID: 4,
            BELOW_OWN_MAXIMUM_BID: 5,
            BELOW_MINIMUM_BID: 6,
            OUTBID_BY_IDENTICAL_BID: 7,
            NOT_LOGGED_IN: 8,
            USER_DISABLED: 9,
            EMERGENCY: 10,
            VERY_HIGH_BID: 11,
            ARTICLE_CLOSED_AND_LOCKED: 12,
            CONFIRM_BUY_IT_NOW: 13,
            SECURITY_DEPOSIT_MANDATORY: 14,
            AUCTION_NOT_STARTED: 15,
            COUNTRY_RESTRICTION: 16
        },

        initialize: function () {
            this.enableListeners();
        },

        initializeLoadEvents: function(){
            this.makeBidButtonSticky();
            this.addLinkUrlToNonModalBidBox();
        },

        makeBidButtonSticky: function(){
            if ('IntersectionObserver' in window) {
                let bb = document.getElementById('bid-button') || document.getElementsByClassName('req-button')[0];
                if (bb !== null) {
                    let fixedEl = document.getElementById('js-bbs');
                    let fixedContact = document.getElementById('contact-fly');
                    if (fixedEl !== null) {
                        let obs = new IntersectionObserver(function (e) {
                            Array.prototype.forEach.call(e, function (el) {
                                if (el.isIntersecting) {
                                    if(SURPLEX.Utils.hasClass(fixedEl,'show')){
                                        fixedEl.classList.remove('show');
                                        if (fixedContact !== null) {
                                            fixedContact.classList.add('d-none');
                                        }
                                    }
                                } else {
                                    if(!SURPLEX.Utils.hasClass(fixedEl,'show')){
                                        fixedEl.classList.add('show');
                                        if (fixedContact !== null) {
                                            fixedContact.classList.remove('d-none');
                                        }
                                    }
                                }
                            })
                        }, {
                            root: null,
                            rootMargin: '0px 0px 700px 0px',
                            threshold: [0]
                        });
                        Array.prototype.forEach.call([bb], function (l) {
                            obs.observe(l);
                        });
                    }
                }
            }
        },

        addLinkUrlToNonModalBidBox: function() {
            let $jsBidBox = $('.js-bidbox.bidbox-wrapper');
            $jsBidBox.each(function(index, element) {
                if ($(element).parents('modal').length === 0) { // This ain't no modal
                    SURPLEX.App.addLinkUrl(
                        $(element).find('.js-link-url')
                    );
                }
            });
        },

        updateBidBox: function () {
            let $detailBiddingElm = $('.js-bidbox');
            const $endTimeContainer = $detailBiddingElm.find(".js-machine--endtime");
            if ($endTimeContainer.length > 0) {
                $endTimeContainer.each(function () {
                    const $activeArticles = $(this).closest(".js-machine--details.js-article--item[data-article-id]");
                    $activeArticles.each(function () {
                        const articleId = $(this).data('article-id');
                        const syncDataUrl = SURPLEX.Utils.decodeBase($('.js-list-urls[data-article-id="' + articleId + '"]').attr("data-auction-update-url"));
                        SURPLEX.Ajax.fetch(
                            {
                                url: syncDataUrl,
                                data: {articleId: articleId, language : SURPLEX.App.pageLanguage},
                                callbackEnd : $.extend(
                                    {
                                        'syncResult': SURPLEX.Bid.syncArticleData
                                    },
                                    SURPLEX.Ajax.getDefaultEndCallbacks(),
                                    {
                                        'setTimer' : function () {
                                            SURPLEX.Timer.requestingUpdate = false;
                                        }
                                    }),
                                loadingTarget : SURPLEX.Bid.userHasRequestedUpdate ? $detailBiddingElm : null
                            }
                        );
                    });

                });
            }
        },

        enableListeners: function () {
            SURPLEX.App.uniqueBindEvent('.js-bid-input', 'keyup', 'bid', function (e) {
                let $e = $('.js-bidInputCurrency');
                if($e.length > 0) {
                    let $val = SURPLEX.Bid.validateInput($(this).val());
                    if ($val > 0) {
                        let $calcVal = Math.round($val * $e.data('exchange-rate'));
                        $e.find('.js-bidInputCurrencyValue').html($calcVal.toLocaleString($e.data('locale')));
                        $e.find('.js-bidInputCurrencyValid').removeClass('d-none');
                        $e.find('.js-bidInputCurrencyInvalid').addClass('d-none');
                    } else {
                        $e.find('.js-bidInputCurrencyValue').html('');
                        $e.find('.js-bidInputCurrencyValid').addClass('d-none');
                        $e.find('.js-bidInputCurrencyInvalid').removeClass('d-none');
                    }
                }

                if (e.keyCode === 13) {
                    $(this).closest('.js-article--item').find('.js-bid-button').get(0).click();
                }
            });
            SURPLEX.App.uniqueBindEvent('.js-quick-bid', 'click', 'bid', function (e) {
                let $value = $(this).attr('data-value');
                let $article = $(this).closest('.js-article--item');
                $article.find("input.js-bid-input").val($value);
                $article.find('.js-bid-button').get(0).click();

            });
            SURPLEX.App.uniqueBindEvent('.js-bid-input', 'change', 'bid', function (e) {
                SURPLEX.Bid.userHasChangedBid = true;
            });

            SURPLEX.App.uniqueBindEvent('#modal-submit-bid_warning', 'click', 'bid', SURPLEX.Bid.modalBidBtn);


            SURPLEX.App.uniqueBindEvent('.js-bid-button', 'click', 'bid', function (e) {
                let articleId = $(this).closest('.js-article--item').data('article-id');
                if (SURPLEX.App.getIsGuest()) {
                    SURPLEX.Bid.addLastBidArticleCookie(articleId);
                    SURPLEX.Track.trackGA(
                        'loginModalTracking',
                        $('meta[name=srxGooglePageType]').attr('content'),
                        'modal_openedBy_bidBtn'
                    );
                    new Modal(document.getElementById('loginModal')).show();
                    return;
                }
                let auctionId = $(this).closest('.js-article--item').data('auction-id');

                if (typeof auctionId === 'undefined' && typeof articleId === 'undefined'){
                    return;
                }

                SURPLEX.Bid.biddingModal = $(this).closest('#bidding-modal').length > 0;
                if (!SURPLEX.Bid.preValidateUser(articleId, auctionId)) {
                    return;
                }

                if (!SURPLEX.Bid.areTermsAndConditionAccepted(articleId)) {
                    SURPLEX.Bid.userHasRequestedUpdate = true;
                    SURPLEX.Bid.confirmTCandBid(auctionId, articleId);
                    return;
                }

                let usersBid = SURPLEX.Bid.getValidatedUserBid(articleId, auctionId);
                if (usersBid) {
                    SURPLEX.Bid.userHasRequestedUpdate = true;
                    SURPLEX.Bid.submitBid(auctionId, articleId, usersBid);
                }
            });

            SURPLEX.App.uniqueBindEvent('.js-machine--details.js-article--item', 'refresh', 'bid', function (e) {
                SURPLEX.Bid.updateBidBox();
            });
            SURPLEX.App.uniqueBindEvent('.js-article-watch--item:not(.js-machine--details)', 'refresh', 'bid', function (e) {
                let $bidArticleItem = $(this);
                let $timerElem = $bidArticleItem.find(SURPLEX.Timer.settings.timersSelector);

                SURPLEX.Ajax.fetch(
                    {
                        url: '/',
                        data: {
                            'r':'Page/machine/reload',
                            'articleId':$bidArticleItem.data('article-id'),
                            'sidebarWatch': $bidArticleItem.find('.delete-item').length > 0,
                            'showLotNumber': $bidArticleItem.find('.js_lot-no').length > 0,
                            'language':SURPLEX.App.getPageLanguage(),
                        },
                        callbackStart : {},
                        callbackEnd : {'recalculate': function (target, result) {
                                if (result.hasOwnProperty('remainingTime') && result.hasOwnProperty('html')) {
                                    if (result.hasOwnProperty('bidStatus')) {
                                        for (let [, bidcolor] of Object.entries(SURPLEX.Timer.settings.bidstatus)) {
                                            bidcolor === result.bidStatus ? $bidArticleItem.addClass(bidcolor) : $bidArticleItem.removeClass(bidcolor);
                                        }
                                    }
                                    if (result.remainingTime >= 0) {
                                        $timerElem.data('closingTime', result.remainingTime);
                                        let remainingTime = SURPLEX.Timer.getRemainingTime($timerElem);
                                        if (remainingTime.timeInSec > 0 && remainingTime.timeInSec > SURPLEX.Timer.settings.progressBarStart) {
                                            SURPLEX.Timer.removeProgressBar($timerElem);
                                            for (let [, urgencyColor] of Object.entries(SURPLEX.Timer.settings.urgencyClassnames)) {
                                                if($timerElem.hasClass(urgencyColor)) {$timerElem.removeClass(urgencyColor)}
                                            }
                                        }
                                        $timerElem.data('updating', 0);
                                        SURPLEX.Timer.resetElem($timerElem);
                                        $bidArticleItem.html(result.html);
                                        $timerElem = $bidArticleItem.find(SURPLEX.Timer.settings.timersSelector);
                                        if ($timerElem.length > 0) {
                                            $timerElem.data('closingTime', $timerElem.attr('data-surplex-closing') * 1);
                                            if (!$timerElem.data('origDateStr')) {
                                                $timerElem.data('origDateStr',
                                                    ((typeof $timerElem.attr('data-orig-date-str') !== 'undefined' && $timerElem.attr('data-orig-date-str') !== '')
                                                            ? $timerElem.attr('data-orig-date-str')
                                                            : $timerElem.html()
                                                    ));
                                            }
                                            SURPLEX.Timer.addElem($timerElem);
                                            SURPLEX.Timer.updateCountdown($timerElem);
                                        }
                                    } else {
                                        SURPLEX.Timer.resetElem($timerElem);
                                        $bidArticleItem.html(result.html);
                                    }
                                    SURPLEX.App.initialize();
                                    SURPLEX.Watch.initialize();
                                    SURPLEX.Bid.enableListeners();
                                }
                            }},
                        loadingTarget : $bidArticleItem,
                        callbackOnSuccess : {},
                        callbackOnFail : function () {
                            SURPLEX.Timer.resetElemHard($timerElem);
                        }
                    }
                )
            });
            SURPLEX.App.uniqueBindEvent('.js-list-bidBtn', 'click', 'bid', function (e) {
                e.preventDefault();
                e.stopPropagation();

                SURPLEX.Bid.renderBidboxModal(this);
                return false;
            });
        },

        modalBidBtn: function (e) {
            if ($(this).closest('.modal-content').hasClass('is-loading')) {
                return;
            }
            let articleId = $(this).data('article-id');
            let $article = $('.js-article--item[data-article-id="' + articleId + '"]');
            let nextPossibleBid = $(this).find('.next-possible-bid-modal').first().html().replace(/ *€/g,'').trim();
            let $input = $article.find("input.js-bid-input").first();
            let $button = $article.find('.js-bid-button').first();

            if (nextPossibleBid !== '' && $input.length && $button.length) {
                $input.val(nextPossibleBid);
                $article.find('.js-bid-button').removeClass('disabled');
                $(this).closest('.modal-content').addClass('is-loading');
                $button.get(0).click();
            }
            let $modalError = $(this).closest('.modal.show');
            if ($modalError.length > 0) {
                Modal.getInstance($modalError.get(0)).hide();
            }
        },

        preValidateUser: function (articleId, auctionId) {
            let article = $('.js-article--item[data-article-id="' + articleId + '"]');
            let bidStatus = article.data("bid-status");

            if (bidStatus === this.BID_STATUS.PLEASE_COMPLETE_PROFILE) {
                SURPLEX.Bid.informUserModal(this.BID_ERROR.PLEASE_COMPLETE_PROFILE);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.PLEASE_COMPLETE_PROFILE, articleId, auctionId, SURPLEX.Bid.usersLastBid);
                return false;
            }

            if (bidStatus === this.BID_STATUS.COUNTRY_RESTRICTION) {
                SURPLEX.Bid.informUserModal(this.BID_ERROR.COUNTRY_RESTRICTION);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.COUNTRY_RESTRICTION, articleId, auctionId, SURPLEX.Bid.usersLastBid);
                return false;
            }

            if (bidStatus === this.BID_STATUS.SECURITY_DEPOSIT_MANDATORY) {
                SURPLEX.Bid.informUserModal(this.BID_ERROR.SECURITY_DEPOSIT_MANDATORY);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.SECURITY_DEPOSIT_MANDATORY, articleId, auctionId, SURPLEX.Bid.usersLastBid);
                return false;
            }
            return true;
        },

        getValidatedUserBid: function (articleId, auctionId) {
            let $article = $('.js-article--item[data-article-id="' + articleId + '"]');

            let $newInput = $article.find("input.js-bid-input").first();

            let ownBid = $article.data("users-max-bid");
            let maxBid = $article.data("machine-max-bid");
            let minBid = $article.data("minimum-bid");
            let buyItNow = $article.data("machine-buy-it-now");
            let iAmHighestBidder = ((ownBid > 0) && (ownBid >= maxBid));

            SURPLEX.Bid.usersLastBid = $newInput.length > 0 ? $newInput.val().trim() : '';
            SURPLEX.Bid.usersHighestBidFormatted = $article.data("users-max-bid-formatted");
            SURPLEX.Bid.proposedBidFormatted = $article.data("proposed-bid-formatted");
            SURPLEX.Bid.proposedBid = $article.data("proposed-bid");
            SURPLEX.Bid.bidStep = $article.data("proposed-bid") - Math.max(ownBid, maxBid);


            SURPLEX.Bid.articleId = articleId;

            let validatedBid = SURPLEX.Bid.validateInput(SURPLEX.Bid.usersLastBid);

            if (validatedBid === false) {
                SURPLEX.Bid.informUserModal(this.BID_ERROR.INVALID_BID);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.INVALID_BID, articleId, auctionId, SURPLEX.Bid.usersLastBid);
                return false;
            }
            if (validatedBid < minBid && !iAmHighestBidder) {
                if (!SURPLEX.Bid.userHasChangedBid && SURPLEX.Bid.userHasRequestedUpdate) {
                    if($newInput.length > 0){
                        $newInput.val(minBid);
                    }
                }
                SURPLEX.Bid.userHasRequestedUpdate = false;
                SURPLEX.Bid.informUserModal(this.BID_ERROR.BELOW_MINIMUM_BID);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.BELOW_MINIMUM_BID, articleId, auctionId, validatedBid);
                return false;
            }
            if (validatedBid <= ownBid) {
                SURPLEX.Bid.informUserModal(this.BID_ERROR.BELOW_OWN_MAXIMUM_BID);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.BELOW_OWN_MAXIMUM_BID, articleId, auctionId, validatedBid);
                return false;
            }
            if (validatedBid < maxBid) {
                SURPLEX.Bid.informUserModal(this.BID_ERROR.OUTBID_BY_IDENTICAL_BID);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.OUTBID_BY_IDENTICAL_BID, articleId, auctionId, validatedBid);
                return false;
            }
            if (validatedBid > (minBid * 10)) {
                SURPLEX.Bid.confirmDialog(this.BID_ERROR.VERY_HIGH_BID, articleId, auctionId, validatedBid);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.VERY_HIGH_BID, articleId, auctionId, validatedBid);
                return false;
            }
            if (buyItNow === 1) {
                SURPLEX.Bid.confirmDialog(this.BID_ERROR.CONFIRM_BUY_IT_NOW, articleId, auctionId, validatedBid);
                SURPLEX.Bid.sendValidationErrorEvent(this.BID_ERROR.CONFIRM_BUY_IT_NOW, articleId, auctionId, validatedBid);
                return false;
            }
            return validatedBid;
        },

        // INFO DIALOG
        informUserModal: function (messageId) {
            let headline = 'ERROR';
            let message = 'UNKOWN ERROR';
            let buttonText = '';
            let buttonUrl = '';
            const infoElement = $('#validationMessage').children('[data-validation-message-' + messageId + ']');
            let $bidWarningModal = $('#bid_warning');
            let $bidButton = $bidWarningModal.find('.modal-footer').find('.js-modal-confirm');

            if (infoElement.length) {
                if (typeof infoElement.data('validation-headline-' + messageId) !== 'undefined') {
                    headline = infoElement.data('validation-headline-' + messageId);
                }
                if (typeof infoElement.data('validation-btn-text-' + messageId) !== 'undefined') {
                    buttonText = infoElement.data('validation-btn-text-' + messageId);
                }
                if (typeof infoElement.data('validation-btn-url-' + messageId) !== 'undefined') {
                    buttonUrl = infoElement.data('validation-btn-url-' + messageId);
                }
                if (typeof infoElement.data('validation-message-' + messageId) !== 'undefined') {
                    message = infoElement.data('validation-message-' + messageId);
                    message = message.
                    replace(/<users-value>/g, SURPLEX.Bid.usersLastBid).
                    replace(/<users-highest-bid>/g, SURPLEX.Bid.usersHighestBidFormatted).
                    replace(/<next-possible-bid>/g, SURPLEX.Bid.proposedBidFormatted);

                    if (messageId === this.BID_ERROR.INVALID_BID
                        || messageId === this.BID_ERROR.BELOW_OWN_MAXIMUM_BID
                        || messageId === this.BID_ERROR.BELOW_MINIMUM_BID) {
                        buttonText = '<span class="next-possible-bid-modal">' + SURPLEX.Bid.proposedBidFormatted + '</span>&nbsp;' +  buttonText;
                        if ($bidButton.length > 0){
                            $bidButton
                                .data('article-id', SURPLEX.Bid.articleId)
                                .data('next-possible-bid-modal', SURPLEX.Bid.proposedBidFormatted)
                                .html(buttonText);
                        } else {
                            $('<button>').attr({
                                "type": "button",
                                "id": "modal-submit-bid_warning",
                                "class": "btn btn--primary js-modal-confirm",
                                "data-article-id": SURPLEX.Bid.articleId,
                                "data-next-possible-bid-modal": SURPLEX.Bid.proposedBidFormatted,
                            }).on('click', SURPLEX.Bid.modalBidBtn).html(buttonText).appendTo($bidWarningModal.find('.modal-footer'));

                        }
                    }
                }

            } else {
                SURPLEX.App.logError('unknwon Error - no validation Message found for error ' + messageId);
                return;
            }
            $bidWarningModal.find('.modal-content').removeClass('is-loading')
            $bidWarningModal.find('.modal-title').html(headline);
            $bidWarningModal.find('.modal-body').html(message);
            if ($bidButton.length < 1 && buttonText !== '' && buttonUrl !== '') {
                $('<button>').attr({
                    'type': 'button',
                    "href": buttonUrl,
                    "data-redirect": buttonUrl,
                    "class": "btn btn--primary js-modal-confirm",
                    "id": "modal-submit-bid_warning"

                }).html(buttonText).appendTo($bidWarningModal.find('.modal-footer'));

            } else if($bidButton.length > 0 && (buttonText !== '' || buttonUrl !== '')) {
                if (buttonText !== '') {
                    $bidButton.html(buttonText);
                }
                if (buttonUrl !== '') {
                    $bidButton.removeAttr("data-bs-dismiss").attr("href", buttonUrl).attr("data-redirect", buttonUrl);
                }
            }
            SURPLEX.Bid.toogleBiddingModal('#bid_warning');
            let warningModalInstance = Modal.getOrCreateInstance($bidWarningModal.get(0));
            if (warningModalInstance !== null) {
                warningModalInstance.show();
            }
            if (messageId === this.BID_ERROR.INVALID_BID
                || messageId === this.BID_ERROR.BELOW_OWN_MAXIMUM_BID
                || messageId === this.BID_ERROR.BELOW_MINIMUM_BID) {
                $bidWarningModal.on('hide.bs.modal', function () {
                    $bidWarningModal.find('.modal-footer').addClass('d-none');
                });
            }
            SURPLEX.App.enableDataRedirect();
        },

        toogleBiddingModal: function(modalBoxId){
            let biddingModal = document.querySelector('#bidding-modal');
            if (biddingModal !== null && biddingModal.classList.contains('show')) {
                let altModal = document.querySelector(modalBoxId);
                altModal.addEventListener('show.bs.modal', function(){
                    Modal.getInstance(biddingModal).hide();
                });
                altModal.addEventListener('hide.bs.modal', function(){
                    Modal.getInstance(biddingModal).show();
                });
            }
        },

        // CONFIRM DIALOG
        confirmDialog: function (messageId, articleId, auctionId, value) {
            let headline = 'ERROR';
            let message = 'UNKOWN ERROR';
            const infoElement = $('#validationMessage').children('[data-validation-message-' + messageId + ']');
            if (typeof infoElement !== 'undefined') {
                if (typeof infoElement.data('validation-headline-' + messageId) !== 'undefined') {
                    headline = infoElement.data('validation-headline-' + messageId);
                }
                if (typeof infoElement.data('validation-message-' + messageId) !== 'undefined') {
                    message = infoElement.data('validation-message-' + messageId);
                    message = message.replace(/XX[,. ]XX/, value);
                    message = message.replace(/ XX/, ' ' + value + ' &euro;');
                }
            }
            let $bidConfirmationModal= $('#bid_confirmation');
            $bidConfirmationModal.find('.modal-title').html(headline);
            $bidConfirmationModal.find('.modal-body').html(message);
            $bidConfirmationModal.find('.js-modal-confirm').off('click').on('click', function () {
                SURPLEX.Bid.submitBid(auctionId, articleId, value);
            });
            SURPLEX.Bid.toogleBiddingModal('#bid_confirmation');
            let bid_confirmationEl = $bidConfirmationModal.get(0);
            if (bid_confirmationEl !== null) {
                let bid_confirmationElModal = Modal.getInstance(bid_confirmationEl) || new Modal(bid_confirmationEl);
                if (bid_confirmationElModal !== null) {
                    bid_confirmationElModal.show();
                }
            }
        },

        // cleanup input of input field
        validateInput: function (value2Validate) {
            const nunNumberRegex = /[^., 0-9]/g;
            if (nunNumberRegex.exec(value2Validate) !== null) {
                return false;
            }
            let finalBiddingAmount = parseInt(value2Validate
                .replace(/(\d+)(([,. ](\d{3})([,. ](\d{3}))?([,. ]\d{2})?)|[,. ]\d{2})/gm, '$1$4$6')
            );
            if (isNaN(finalBiddingAmount) || finalBiddingAmount === 0) {
                return false;
            }
            return finalBiddingAmount;
        },

        sendValidationErrorEvent(responseCode, articleId, auctionId, userbid) {
            try {
                let userId = SURPLEX.Track.getDataFromLayer('dataLayer', 'userId');
                SURPLEX.Track.trackGA("Gebot", "bid-validation-error", userId + '-' + articleId + '-' + userbid, responseCode);
                // send Hotjar user attribute
                if (typeof hj === 'function') {
                    hj('identify', userId, {
                        ['bidErrorCode-' + responseCode] : 'occurred'
                    });
                }
            } catch (err) {
                SURPLEX.App.logError(err);
            }
        },

        // execute bid request
        submitBid: function (auctionId, articleId, usersBid) {
            let $bidUrlDiv = $('.js-list-urls[data-article-id="' + articleId + '"]');
            let $loadingTarget = undefined;
            let $biddingEl = undefined;
            if ($bidUrlDiv.length === 0) {
                // watchlist bidding
                $loadingTarget = $('.js-article--item[data-article-id="' + articleId + '"]');
                $bidUrlDiv = $loadingTarget.closest('.js-list-urls');
            } else{
                $biddingEl = $('.js-bidbox');
                $biddingEl.addClass('is-loading');
            }
            const bidUrl = SURPLEX.Utils.decodeBase($bidUrlDiv.data('bid-url'));
            const bidSource = $bidUrlDiv.data('bid-url-source');

            let clientId = 'NA';
            if (typeof ga === 'function') {
                ga(function (tracker) {
                    clientId = tracker.get('clientId');
                });
            }
            if (SURPLEX.TrafficSource.isNotCookieSet()) {
                SURPLEX.TrafficSource.setSurplexSourceCookie();
            }
            SURPLEX.Ajax.post(
                {
                    url: bidUrl,
                    data: {
                        auctionId: auctionId,
                        articleId: articleId,
                        bid: usersBid,
                        source: bidSource,
                        clientId: clientId
                    },
                    callbackStart : $.extend(
                        SURPLEX.Ajax.getDefaultStartCallbacks(),
                        {
                            'onStartHandler': function () {
                                SURPLEX.Bid.lastBidResult = {};
                            }
                        }
                    ),
                    loadingTarget : $biddingEl || $loadingTarget ,
                    callbackOnSuccess : $.extend({'biddingResult': SURPLEX.Bid.bidResult}, SURPLEX.Ajax.getDefaultSuccessCallbacks())
                }
            );
        },

        // bid was sent to server
        // update ".auction-article-bidding"
        // -> Modal Info if error was returned
        bidResult: function (target, data) {
            const result = SURPLEX.Utils.isJSON(data);
            if (result) {
                let errorModalEl = document.getElementById('bid_warning');
                if(errorModalEl !== null){
                    let errorModal = Modal.getInstance(errorModalEl);
                    if(errorModal !== null){
                        errorModal.hide();
                        let $modalBackdrop = $('.modal-backdrop.fade.show');
                        if($modalBackdrop.length > 0){
                            $modalBackdrop.remove();
                        }
                    }
                }
                if (result.errorCode > 0) {
                    SURPLEX.Bid.usersHighestBidFormatted = result.highestBidOfUser_formated;
                    SURPLEX.Bid.proposedBidFormatted = result.proposedBid_formated;
                    SURPLEX.Bid.informUserModal(result.errorCode);
                    SURPLEX.Track.trackGA("Gebot", "bid-invalid", SURPLEX.Bid.usersLastBid, SURPLEX.Bid.usersLastBid);
                    // send Hotjar user attribute
                    if (typeof hj === 'function') {
                        hj('identify', result.userId, {
                            'bidValue' : result.newBid,
                            ['bidStatus-' + result.returnStatus] : 'occurred',
                            ['bidErrorCode-' + result.errorCode] : 'occurred'
                        });
                    }
                } else {
                    SURPLEX.Track.trackGA("Gebot", "bid-success", SURPLEX.Bid.usersLastBid, SURPLEX.Bid.usersLastBid);

                    if(result.highestBidOfUser > SURPLEX.Bid.proposedBid){
                        SURPLEX.Track.trackGA("Bid", "BidWithBidAgent", SURPLEX.Bid.usersLastBid, result.highestBidOfUser);
                        if(SURPLEX.Bid.usersLastBid > SURPLEX.Bid.proposedBid + 3 * SURPLEX.Bid.bidStep){
                            SURPLEX.Track.trackGA("Bid", "BidWithBidAgentOver4BidStep", SURPLEX.Bid.usersLastBid, result.highestBidOfUser);
                        }
                    }

                    if (result.hasOwnProperty('toast') && result.toast.hasOwnProperty('title') && result.toast.title !== '') {
                        $.fn.surplexToast({
                            title: SURPLEX.Utils.decodeBase(result.toast.title),
                            content: SURPLEX.Utils.decodeBase(result.toast.content),
                            time: result.toast.time,
                            status: result.toast.status,
                            id: result.toast.id
                        });
                    }

                    // send Hotjar user attribute
                    if (typeof hj === 'function') {
                        hj('identify', result.userId, {
                            'bidValue' : result.newBid,
                            ['bidStatus-' + result.returnStatus] : 'occurred'
                        });
                    }
                    if (result.hasOwnProperty('eCommerce') && result.eCommerce.bid > 0) {
                        SURPLEX.Track.trackGTagBid({
                            'auctionId': result.eCommerce.auctionId,
                            'bid': result.eCommerce.bid,
                            'bidId': result.eCommerce.bidId,
                            'articleId': result.eCommerce.articleId,
                            'articleName': result.eCommerce.articleName,
                            'articleManufacturer': result.eCommerce.articleManufacturer,
                            'articleCategoryPath': result.eCommerce.articleCategoryPath,
                            'articleCountry': result.eCommerce.articleCountry,
                            'language': result.eCommerce.language,
                            'pageType': $('meta[name=srxGooglePageType]').attr('content')
                        });
                    }
                }
                SURPLEX.Track.trackBidDelta(result.bidSource, result);

                SURPLEX.Bid.userHasRequestedUpdate = true;
                SURPLEX.Bid.userHasSubmittedBid = true;
                if (result.hasOwnProperty('bidbox')) {
                    SURPLEX.Bid.syncArticleData(target, result.bidbox);
                }
                const $articleList = $('.js-article-watch--item[data-article-id="' + result['articleId'] + '"]:not(.js-machine--details)');
                if (SURPLEX.Bid.biddingModal && $articleList.length > 0) {
                    if ($articleList.closest('.js-watchlist').length > 0) {
                        SURPLEX.Filter.collectFilter(false, '');
                    } else {
                        $articleList.trigger('refresh');
                    }
                }
            }
        },

        // check if Terms&Conditions are accepted
        areTermsAndConditionAccepted: function (articleId) {
            return $('.js-article--item[data-article-id="' + articleId + '"]').data('terms-accepted') === 1;
        },

        confirmTCandBid: function (auctionId, articleId) {
            $('#confirmTermsAndConditions input').off('click').on('click', function () {
                $('#confirmTermsAndConditions .js-modal-confirm').prop('disabled', !$(this).is(':checked'));
            });
            $('#confirmTermsAndConditions .js-modal-confirm').off('click').on('click', function () {
                let $bidTermUrlDiv = $('.js-list-urls[data-article-id="' + articleId + '"]');
                if ($bidTermUrlDiv.length === 0) {
                    $bidTermUrlDiv = $('.js-article--item[data-article-id="' + articleId + '"]').closest('.js-list-urls');
                }
                const bidTermsUrl = SURPLEX.Utils.decodeBase($bidTermUrlDiv.data("bid-terms-url"));
                if (typeof bidTermsUrl === 'undefined') {
                    SURPLEX.App.log('failed to get url');
                }
                let $loadingTarget = $('.bidbox.auction-article-bidding');
                if ($loadingTarget.length === 0) {
                    // watchlist bidding
                    $loadingTarget = $('.js-article--item[data-article-id="' + articleId + '"]');
                }

                // submitBid or updateBidbox will remove the loading class
                $loadingTarget.addClass('is-loading');

                SURPLEX.Ajax.post(
                    {
                        url: bidTermsUrl,
                        data: {auctionId: auctionId, articleId: articleId},
                        callbackEnd : $.extend(SURPLEX.Ajax.getDefaultEndCallbacks(),
                            {
                                'placebid':
                                    function (target, data) {
                                        const result = SURPLEX.Utils.isJSON(data);
                                        let $confirmTermsEl = $('#confirmTermsAndConditions');
                                        if ($confirmTermsEl.length > 0) {
                                            let confirmTermsModal = Modal.getInstance($confirmTermsEl.get(0));
                                            if (confirmTermsModal !== null) {
                                                confirmTermsModal.hide();
                                            }
                                        }
                                        if (result.hasOwnProperty('success') && result.success === true) {
                                            window.setTimeout(function () {
                                                let usersBid = SURPLEX.Bid.getValidatedUserBid(articleId, auctionId);
                                                $('.js-article--item[data-article-id="' + articleId + '"]').data('terms-accepted', 1).attr('data-terms-accepted', 1);
                                                if (usersBid) {
                                                    SURPLEX.Bid.submitBid(auctionId, articleId, usersBid);
                                                } else {
                                                    SURPLEX.Bid.updateBidBox();
                                                }
                                            }, 500);
                                        } else {
                                            SURPLEX.Bid.updateBidBox();
                                        }
                                        $loadingTarget.removeClass('is-loading');

                                    }
                            }
                        )
                    }
                );
            });
            let $confirmTermsEl = $('#confirmTermsAndConditions');
            if ($confirmTermsEl.length > 0) {
                SURPLEX.Bid.toogleBiddingModal('#confirmTermsAndConditions');
                let confirmTermsModal = Modal.getOrCreateInstance($confirmTermsEl.get(0));
                if (confirmTermsModal !== null) {
                    confirmTermsModal.show();
                    $('.js-stac-bid-amount').first().html($("input.js-bid-input").first().val().trim());
                }
            }
        },

        syncArticleData: function (target, data) {
            if (SURPLEX.Bid.userHasSubmittedBid) {
                SURPLEX.Bid.userHasChangedBid = false;
                SURPLEX.Bid.userHasSubmittedBid = false;
            }
            const result = SURPLEX.Utils.isJSON(data);
            if (result.hasOwnProperty('forceReload') && result.forceReload === true) {
                window.location.reload();
            }
            for (let articleId in result) {
                const $articleContainer = $('.js-article--item[data-article-id="' + articleId + '"]');
                if ($articleContainer.length && result.hasOwnProperty(articleId)) {
                    for (let elemClassOrAttr in result[articleId]) {
                        const $contentElement = $articleContainer.find("." + elemClassOrAttr);
                        if ($contentElement.length && result[articleId].hasOwnProperty(elemClassOrAttr)) {
                            if ('content' in result[articleId][elemClassOrAttr]) {
                                let newContent = result[articleId][elemClassOrAttr]['content'];
                                SURPLEX.Bid.replaceChangedContent($contentElement, newContent, elemClassOrAttr);
                            }
                            if ('newInputValue' in result[articleId][elemClassOrAttr]) {
                                if ('requestedOnly' in result[articleId][elemClassOrAttr]) {
                                    // Do _not_ change value only if requestedOnly is true and userHasRequestedUpdate is false:
                                    if (!result[articleId][elemClassOrAttr]['requestedOnly'] || SURPLEX.Bid.userHasRequestedUpdate) {
                                        if ('unchangedOnly' in result[articleId][elemClassOrAttr]) {
                                            if (result[articleId][elemClassOrAttr]['unchangedOnly'] === 'bid' && !SURPLEX.Bid.userHasChangedBid) {
                                                $contentElement
                                                    .val(result[articleId][elemClassOrAttr]['newInputValue'])
                                                    .attr('value', result[articleId][elemClassOrAttr]['newInputValue']);
                                            }
                                        } else {
                                            $contentElement
                                                .val(result[articleId][elemClassOrAttr]['newInputValue'])
                                                .attr('value', result[articleId][elemClassOrAttr]['newInputValue']);
                                        }
                                    }
                                } else {
                                    $contentElement.val(result[articleId][elemClassOrAttr]['newInputValue']);
                                }
                            }
                        } else if(elemClassOrAttr === 'attributes' && typeof result[articleId][elemClassOrAttr] === 'object'){
                            for (let [attributeName, attributeValue] of Object.entries(result[articleId][elemClassOrAttr])) {
                                $articleContainer.data(attributeName.replace('data-',''), attributeValue).attr(attributeName,attributeValue);
                            }
                        }
                    }
                }
            }
            SURPLEX.Bid.userHasRequestedUpdate = false;
        },

        replaceChangedContent: function ($contentElement, newContent, fieldId) {
            let oldContent = $contentElement.html();
            if(oldContent !== newContent) {
                if(
                    SURPLEX.Bid.removeAllForComparison(oldContent) !== SURPLEX.Bid.removeAllForComparison(newContent) &&
                    fieldId !== 'machine--eTCounter'
                ) {
                    $contentElement.addClass('animated pulse faster');
                    $contentElement.on('animationend', function () {
                        $contentElement.removeClass('animated pulse faster');
                    });
                }
                $contentElement.html(newContent);
            }
        },

        removeAllForComparison: function (str) {
            str = str.replace(/(<([^>]+)>)/ig,"").replace(/\s/g,'');
            str = SURPLEX.Bid.decodeEntities(str);
            return str;
        },

        decodeEntities: function (str) {
            if (typeof $.parseHTML(str)[0] === "undefined") {
                return str;
            }
            return $.parseHTML(str)[0].textContent;
        },

        addLastBidArticleCookie: function (articleId) {
            articleId = parseInt(articleId);
            let cookieStoreLastBidItems = SURPLEX.Utils.isJSON(SURPLEX.Utils.getCookie('surplex_last_bid_article')) || [];
            if (cookieStoreLastBidItems.includes(articleId) === false) {
                if (cookieStoreLastBidItems.length === 5) {
                    cookieStoreLastBidItems = cookieStoreLastBidItems.slice(1);
                }
                cookieStoreLastBidItems.push(articleId);
                SURPLEX.Utils.setCookie(
                    'surplex_last_bid_article',
                    JSON.stringify(cookieStoreLastBidItems),
                    (2 * 24 * 60 * 60 * 1000) //2 days
                );
            }
        },

        renderBidboxModal: function (element) {
            let articleId = $(element).closest('.js-article-watch--item').attr('data-article-id');
            let bidSource = $(element).closest('.js-article-watch--item').attr('data-bid-source');
            SURPLEX.Track.trackGA('BiddingOnCollection', 'click', articleId, bidSource);
            if (SURPLEX.App.getIsGuest()) {
                SURPLEX.Bid.addLastBidArticleCookie(articleId);
                SURPLEX.Track.trackGA(
                    'loginModalTracking',
                    $('meta[name=srxGooglePageType]').attr('content'),
                    'modal_openedBy_bidBtn'
                );
                let loginElem = document.getElementById('loginModal');
                let logInModal = Modal.getInstance(loginElem) || new Modal(loginElem);
                logInModal.show();
                return;
            }
            SURPLEX.Ajax.fetch(
                {
                    url: '/',
                    data: {'r':'bid/bid-box-modal','articleId':articleId,'language':SURPLEX.App.pageLanguage, 'bidSource':bidSource},
                    callbackStart : {},
                    callbackEnd : {'showModal': function (target, res) {
                            if (res.hasOwnProperty('success') && res.success === true && res.hasOwnProperty('bidBoxModal')) {
                                $('#bidding-modal, #bidding-modal-additional').remove();
                                $(res.bidBoxModal).appendTo("body");
                                let biddingModal = document.getElementById('bidding-modal');
                                let bidModal = new Modal(biddingModal);
                                biddingModal.addEventListener('shown.bs.modal', function(){
                                    SURPLEX.App.initialize();
                                    SURPLEX.Watch.enableListeners();
                                    SURPLEX.Watch.updateWatchlistBadge();
                                    SURPLEX.Bid.enableListeners();
                                    SURPLEX.Timer.initialize();
                                });
                                bidModal.show();
                                SURPLEX.App.addLinkUrl(
                                    $(biddingModal).find('.js-link-url')
                                );
                            }
                        }},
                    loadingTarget : $(element)
                }
            )
        },

    });
    if (typeof SURPLEX.Bootstrap !== 'undefined') {
        SURPLEX.Bootstrap.registerBootstrap(SURPLEX.Bid);
    }
})($, SURPLEX);